.custom-card-header {
    border: none;
    background-color: transparent !important;
}


.card {
    border: 1px solid #03b5d2 !important;
}

.input-task {
    color: #03b5d2;
    text-decoration: none;
}

/* Temporary CSS */

.Task-list-dropdown {
    border: 1px solid #E4E6E9;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: #f6f7f8 0px 2px 8px 0px;
    --placeholder-opacity: 1;
}

ion-select::part(icon) .select-icon-inner  {
    color: red !important;
}
