/***************** Reservations ***************/

.reservations {
  padding: 1rem 2rem;
}

.reservations__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.reservations__heading {
  margin: 0;
}

.mr-icon {
  margin-right: 5px;
}

/**************** Health Guide ****************/

.health__guide {
  border: 1px solid #cac3c3;
  padding: 1rem;
  border-radius: 12px;

  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.health__heading {
  color: #414141;
  font-weight: 700;
  font-size: 22px !important;
}
.health__icon {
  margin-right: 20px;
  font-size: 35px;
}

/************ Table ***********/

.table-line {
  border-bottom: 1px solid #ececec;
  padding: 10px;
}

.row-align {
  display: flex;
  align-items: center;
}

.cm-color {
  color: #7f7f7f;
  font-weight: 500;
}

.cell-name {
  font-size: 14px;
  color: #4e9a9e;
  font-weight: 500;
}

.cm-color-2 {
  font-weight: 500;
  font-size: 14px;
}

.check-col {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #2D7A7C;
  cursor: pointer;
}

.react-tabs__tab--selected {
  border: none;
  border-bottom: 3px solid #4e9a9e !important;
  border-color: none;
  color: #4e9a9e;
}
