.header-card {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #dfd3d3;
}

.member-list {
  background: white;
  border-radius: 12px;
  box-shadow: #dfdbdb 0px -1px 5px 2px;
  display: flex;
  cursor: pointer;
  padding: 6px;
}

.member-content > span {
  display: block;
  padding: 2px 14px;
}

.member-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.member-list-image {
  overflow: hidden;
  border-radius: 10px;
}

.member-list-active {
  background: #118b8f;
  color: white;
}

.assigned-mem {
  background: #cfdfff;
  padding: 7px;
  border-radius: 7px;
  margin-right: 5px;
}

.assignee-acc-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.assignee-acc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.assignee-acc > span {
  display: block;
  font-size: 16px;
}

.assignee-acc-container > img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.assignee-remove-btn {
  min-width: 30px;
  height: 30px;
}
