.settings-root {
  padding: 15px;
}
.settings-root h1 {
  font-weight: bold;
}
.settings-root h6 {
  font-size: 17px;
}
.settings-root p {
  font-size: 13px;
}
.settings-root .company-name,
.company-name:focus {
  border: 1px solid #ccc;
}
.settings-root .save-btn {
  --border-radius: 3px;
  text-transform: none;
}
.settings-root .upload-section {
  min-height: 100px;
  border: 1px dashed #ccc;
}
.settings-root .learn-btn {
  text-transform: none;
  float: right;
}


#team-save {
  -webkit-appearance: none;
  appearance: none;
 width: 100%;
 height: 20px;

}

#team-save::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

#team-save::-webkit-progress-value {

  background-color: #398c90;

    border-radius: 2px; 
    background-size: 35px 20px, 100% 100%, 100% 100%;
  } 
