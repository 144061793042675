body{
    color: #484848;
}

a {
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.user-auth-modal {
    --width: 100%  !important;
    --height: 100vh !important;
}

.new-user-modal {
    font-weight: 500 !important;
}

.message {
    display: inline-block;
    margin-top: 10px;
    font-weight: 500;
}

.mlr-auto {
    margin: 0 auto;
}


.cus-width {
    width: 100% !important;
}

.taskListDelete {
    position: absolute;
    width: min-content;
    height: 40px;
    top: 8px;
    right: 18px;
    z-index: 11111;
}

.sm-text {
    text-align: center;
}


.bg-main-content {
    --background: #F3F4F4;
}
/********** Media Query **********/

@media only screen and (max-width:375px) {
   
 .item-native {
    padding-inline-start: 0px !important;
 }
 .member-permissions {
     padding: 0px !important;
 }
 .button-sm-res {
    display: block;
    max-width: 50%;
  }
  .view-listing-sec {
    padding: 0 !important;
}

.button-inner {
    font-size: 11px;
} 
}

@media only screen and (max-width:402px) {
    .settings-btn {
        margin-left: -11px;
    }

    .mobile-hide {
        display: none;
    }

}

@media only screen and (max-width: 500px) {
   
    .segment-full {
        font-size: 12px  !important;

    }

    .team-segment {
        font-size: 12px !important;
    }
    
    .team-btn-data {
        font-size: 22px !important;
    }

    .team-btn-header {
        font-size: 21px;
        margin-right: auto;
    }
    .team-dropdown{
        width: 300px !important;
        left: 16px !important;
    }

    .sm-text {
        text-align: left !important;
    }
  
}

@media only screen and (max-width:600px) {
    .schedule-details {
        grid-column: 2 / span 2;

    }

    .grid-align {
        grid-column: 1 / span 2;
    }

}
@media only screen and (max-width:768px) {

    .navbar-side {
        margin-top: 57px !important;
    }
   
    .upper-header {
       display: none;

    }
    .modal-radius {
        --border-radius	: none !important;
    
    }
    .center-col  {
        margin-left: 0px !important;
    }
    .margin-top {
        margin-top: 70px;
    }
    .react-datepicker-popper {
        left: -110px !important;
    }
}





@media only screen and (max-width: 1035px) {
   
    .schedule-chip {
        font-size: 14px  !important;

    }
    
}



.user-dropdownn {
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 11111111111111;
    width: 220px;
    border: 1px solid #a5a59d;
    border-radius: 8px;
    overflow: hidden;
}