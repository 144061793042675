a:link,
a:visited {
  text-decoration: none;
  color: black;
  font-weight: 500;
}

ul {
  list-style-type: none;
}

.header {
  display: none;
  align-items: center;
  background-color: white;
}

@media (min-width: 992px) {
  .header {
    display: flex;
  }
  ion-split-pane[content-id="main"] {
    margin-top: 60px;
  }
}

.header-list {
  display: flex;
  align-items: center;
  margin: 0 !important;

}

.header-list-rt {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px !important;
}
.header-list > li {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: -6px;
}
/* .header-list > li:not(:last-child) {
  margin-right: 1rem;
} */

.header-link {
  padding: 1rem 15px;
  position: relative;

font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 26px;

color: rgba(25, 32, 36, 0.4);

}
.header-link__active {
  border-bottom: 3px solid #018787;
  padding-bottom: calc(1rem - 3px);
}
.badge-header {
  right: -4px;
  position: absolute;
  top: 4px;
  font-size: 10px;
  background: #018486;
  border-radius: 10px;
}


.new-clr-btn {
  --background: #1E9294;
  --border-radius: 6px !important;
  --box-shadow: none ;
    margin: 0 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
}

.name-avator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 100%;
  background: #018486;
  color: white;
  width: 42px;
  height: 42px;
  margin-left: 50px;
}


.icon-schedule {
  background: #F6F6F6;
  padding: 10px;
  border-radius: 21px;
  cursor: pointer;
}


.main-header {
  background: #FFFFFF;
  box-shadow: 0px 0.5px 0px rgb(0 0 0 / 25%);
  min-height: 65px;
}

.main-header-container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.brand__logo {
font-family: Work Sans;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 40px;

letter-spacing: -0.02em;
color: #444d52;
}

.brand__logo--1 {
  color: #444d52;
}

.brand__logo--2 {
  color: #1E9294;
}
  


.custom-merge {
  margin-right: 40px;
}
.header {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.mob-header::after {
  background-image: none;
}
.mob-header-bg {
  --background: none;
}