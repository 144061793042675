.invite-modal {
  padding: 15px;
}
.invite-modal h6{
  font-size: 17px;
}
.invite-modal p{
  font-size: 13px;
}
.invite-modal .email {
  border: 1px solid #ccc;
}
.invite-modal .close-icon{
  font-size: 20px;
   float:right;
   cursor: pointer;
} 
.add-btn{
   float: right;
  --border-radius: 3px;
  text-transform: none;
}
 .learn-btn{
  text-transform: none;
}