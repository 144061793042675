.IonSelect {
    border: 1px solid;
    border-radius: 5px;
}

.input-label {
    font-size: 18px !important;
    font-weight: 700 !important;
    width: 100% !important;
}
.back-btn{
    text-transform: none;
    --padding-start:0px
}