/*************** Overlay  ****************/
.overlay {
    position: fixed; 
    width: 100%;
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; 
  }

  /********** Navbar **********/

  .navbar-side {
    height: calc(100vh - 56px);
    position: fixed;
    top: 0;
    right: 0;
    transition: 0.2s;
    z-index: 23232;
    background-color: white;
    overflow-y: scroll;
}


.nav-header {
    background: white;
    padding: 13px;
    font-size: 20px;
}

.nav-header > span {
    cursor: pointer;
}

.additional-note {
    padding: 0px 12px;
}
.additional-text {
    background: #F4F4F4;
    display: block;
    border-radius: 6px;
    padding: 12px !important;
    border: 1px solid #D0D0D0;
}

.text-underline {
    text-decoration: underline;
    cursor: pointer;
}

.adress-label {
    font-size: 14px;
    color: grey;
}

.name-container {
    display: flex;
    align-items: center;
    align-content: center;
}

.schedule-chip {
    display: flex;
    width: 100%;
    --background: #FFFFFF;
    justify-content: space-between;
    border-radius: 7px;
    border: 2px solid #8C8C8C;
    background: transparent;
    font-size: 16px;
    min-height: 41px;
    margin: 3px;
    
}


.schedule-header {
    padding: 14px 7px;
    border-bottom: 1px solid #e1dcdc;
}
.schedule-header::after {
    display: none;
}

.buttons-side > span {
    display: inline-block;
    padding: 7px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    cursor: pointer;
}

.border-r {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-right: 1px solid;
    border-left: 1px solid;
}

.border-l {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-right: 1px solid;
}

.schedule-details {
    margin-left: 10px;
    padding: 10px 0;

}

.schedule-heading {
    margin: 0;
    font-size: 16px;
}

.heading-time {
    font-size: 13px;
    color: #979797;
    margin: 0;
    font-weight: 400;
    padding: 4px 0;
}

.schedule-details-btn {
    text-decoration: underline;
    font-size: 14px;
}

.divider-label {
    margin-top: 22px;
    padding-left: 4px;
    font-size: 31px;
    cursor: unset;
    margin-left: 3px;
}

.modal-radius {
    --border-radius	: 10px;

}

.schedule-label {
    font-size: 15px;
    font-weight: 700;
}

.center-col {
    margin-left: 46px ;
}

.margin-row {
    margin-top: -10px;
}


.modal-x {
    align-self: center; padding-left: 14px; font-size: 24px; cursor: pointer
}


.schedule-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.schedule-item {
    border: 1px solid #dbd2d2;
    border-radius: 6px;
    padding: 6px 0px;
}
.dates-label {
    margin-left: 10px;
    margin-top: 1px;
}
.padding-top-schedule {
    padding: 15px 0px;
}

/********* Calendar **********/
.calendar-brand {
    border: 1px solid #DDDDDD;
    padding: 12px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 61px;
    cursor: pointer;
    background-color: white;
}

.calendar-brand__text {
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
}

.calendar-align  {
    display: grid;
    grid-template-columns: min-content;
}
.calendar-align > ion-button {
    align-self: center;
}

.list{
    border-bottom: 1px solid #ccc;
    padding: 15px 0px;
    cursor: pointer;
    color: var(--ion-text-color);
  }.segment-full {
    max-width: 100%;
}

.react-timekeeper {
    margin-top: -100px;
}

.align-grid {
    display: grid;
    grid-template-columns: 100px 1fr .5fr;
    width: 100%;
}

.dropdown-assign-cleaner {
    z-index: 11111121;
    position: absolute;
    top: 160px;
    right: 56px;
    min-width: 290px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.rdrDateRangeWrapper  {
    position: absolute;
    z-index: 23232;
    background: white;
    border: 1px solid;
    left: 10px;
}

.loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #018486;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 30px auto;
}
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


 .no-cleaning {
    color: gray;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 400;
    margin-left: 6px;
}   
.no-cleaning > span {
    margin-left: 10px;
    padding: 8px 0;
}

.custom-styles {
    color: #484848;
    font-size: 17px;
    font-weight: 700;
}

.side-button:active {
    background-color: grey;
    color: white;
}

.align-self-center {
    align-self: center;
}