.Timer {
    border: 2px solid grey;
    padding: 16px;
    border-radius: 10px;
  }
  
  .timer-text {
    text-align: center;
  }
  
  .timer-buttons button {
    margin: 4px;
  }

  .icon-green {
    color: green
  }

  .icon-red {
    color: red
  }