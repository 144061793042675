.activity-root{
   padding: 15px;
}
.activity-root .heading{
    font-weight: bold;
    font-size: 20px;
}
.activity-root .activities-step{
    border-left: 1px solid #ccc;
    padding: 10px 0px 1px 25px;
    position: relative;
    margin-left: 11px;
}
.activities-step:before{
    position: absolute;
    top: 0px;
    z-index: 1;
    height: 35px;
    width: 15px;
    background-color: #fff;
    content: "";
    border-radius: 28px;
    left: -7px;
}

.activities-step:after{
    position: absolute;
    top: 15px;
    z-index: 2;
    border-radius: 15px;
    height: 10px;
    content: "";
    width: 10px;
    left: -5px;
    background-color: #ccc;
}