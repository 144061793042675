



/***** Template Card *****/
.template-container {
    padding: 84px 0;
    text-align: center;
}


.template-label {
    display: block;
    color: #118B8F;
    font-weight: bold;
}

.template-icon {
    display: inline-block;
    font-size: 25px;
    color: #118B8F;
}

.template-card {
    height: 210px;
    cursor: pointer;
}


/*********** Button ************/

.btn-create {
    --border-color: black;
    --color: black;
}

.btn-edit {
    --border-color: #118B8F;
    --color: #118B8F;
}