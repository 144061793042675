.listing-add-edit{
    padding: 15px;
}
.listing-add-edit .heading {
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
.listing-add-edit .listing-fields{
    border: 1px solid #ccc;
    border-radius: 6px;
}
.listing-add-edit .listing-labels{
    margin: 10px;
}
.listing-add-edit .listing-focus-labels{
    color: var(--ion-color-primary);
    margin: 10px;

}
.listing-add-edit .listing-focus-fields{
    border: 1px solid var(--ion-color-primary);
    border-radius: 6px;
}
.listing-add-edit .listing-errors{
    color: #db3b3b;
    font-size: 11px;
    margin-bottom: 15px;
}
.listing-add-edit .listing-label-margin{
    margin-bottom: 15px;
}
.listing-add-edit .form-error-section{
    height: 26px;
    background: var(--ion-color-primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
}


.css-26l3qy-menu {
    z-index: 9999;
}

.react-timekeeper {
    position: absolute !important;
    z-index: 1111111 !important;
}

.edit-image {
    display: block;
    width: 110px;
    margin-left: 15px;
}

.padding-input{
    padding: 0px 10px  !important;
}


.overwrite-chip {
    border: 1px solid #ccc !important;
    border-radius: 6px !important;
}

.red {
    color: red;
}


.btn-file-upload {
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
    position: absolute;
    opacity: 0;
    z-index: 3434343;
}
