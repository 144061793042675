.select-container {
    position: absolute;
    z-index: 3323232323232323;
    width: 150%;
}

.select-list {
    background-color: #fefefe !important;
    border-radius: 10px !important;
    box-shadow: 1px 0px 20px 0px #676767ba;
}

.cleaning-modal > .modal-wrapper {
    max-width: 800px !important;
    width: 800px !important;
}

.date-item  {
    max-height: 33px !important;
    margin-top: 3px;
}

.react-datepicker-popper {
    margin-top: 14px !important;
    z-index: 1111 !important;
}

.react-datepicker-wrapper {
    display: block !important;

}

.react-datepicker__input-container > input {
    padding: 3px;
    margin-top: 6px;
    height: 36px !important;
    width: 100% !important;
    padding: 3px;
    border: 2px solid #8C8C8C;
    border-radius: 4px;

}


.react-datepicker-ignore-onclickoutside {
    border-radius: 4px !important;
    width: 100% !important;
}