.dashboard-header {
  display: flex;
  justify-content: space-between;
}

.stats {
  display: flex;
}

.stats-list {
  display: flex;
  flex-direction: column;
}
.stats-list:not(:last-child) {
  margin-right: 10px;
}

.stats-content {
  font-weight: 700;
}

.stats-list-text {
  color: #848484;
  font-size: 14px;
  font-weight: 500;
}

.react-tabs__tab--selected {
  border: none;
  border-bottom: 2px solid #262626 !important;
  border-color: none;
}

.react-tabs__tab {
  font-size: 18px;
  font-weight: 500;
}

/******* brief *********/

.brief {
  width: 400px;
}

.brief__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
.brief__heading {
  font-size: 25px !important;
}

.brief__icon {
  display: flex;
  margin-top: 2px;
  font-size: 30px;
}

.brief__icon-box {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

/************** EXP **********/
.exp {
  background-color: #f7f7f7;
  padding: 4rem 2rem;
}

.slide-count {
  font-size: 15px;
  color: black;
  margin-right: 20px;
}

.divider {
  height: 1px;
  background: #e1e1e1;
  margin: 0.5rem 0;
}
/**** Announcement ****/

.announcement {
  background-color: white;
  padding: 2rem 1rem;
  border-radius: 15px;
  /*   box-shadow: 0px 1px 9px 0px #e1d5d5;
 */
}

.announcement__image {
  width: 45px !important;
  height: 45px;
}

.announcement__heading {
  color: #4d4d4d;
  font-weight: 700;
}

.announcement__paragraph {
  color: #6e6e6e;
  font-size: 15px;
  font-weight: 500;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  background-color: transparent;
  text-align: left;
}

/***************** Progress ****************/

.header-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 19px;
  font-weight: 500;
}

/********* opportunity********/

.opportunity__brief {
  font-size: 14px;
  word-spacing: 3px;
  color: #858585;
  padding: 7px 0;
  display: block;
}

/****** Opportunity Listing*****/

.list-count-box {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-weight: 500;
}

.fixed-width-report {
  width: 100%;
  max-width: 1500px;
}

.report-content {
  --background: #F3F4F4;
}

.segment-report {
  min-width: 50%;
}

.segment-report::part(native) {
  min-width: 100%;
}

.segment-main {
  border-bottom: 1px solid rgba(25, 32, 36, 0.2);
    margin-bottom: 30px;
}

.report-header {
  background: #FFFFFF !important; 
box-shadow: 0px 0px 12px rgba(17, 17, 26, 0.1) !important;
border-radius: 6px !important;
height: 60px;
align-items: center;
}

.report-card  {
  border: none !important;
  padding-left: 25px;
  padding-right: 25px;
}
.report-inner-table {
  background: #F3F4F4;
border-radius: 12px;
margin-bottom: 34px;

}

.report-table-header {
  --background: none;
  margin-bottom: 20px;

}
.report-table-header-text {
  font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 26px;

color: rgba(25, 32, 36, 0.4);
}

.report-main-content-table {
  
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 17px;
line-height: 26px;

color: #192024;
}

.report-table-label {
  font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
color: rgba(25, 32, 36, 0.4);
}

.report-table-content {
  font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;

color: #192024;

}

.report-list-item {
  min-height: 70px;
  border-bottom: 1px solid rgba(25, 32, 36, 0.2);
  padding: 10px;
}

.report-list-item:last-child {
  border: none;
}
.collapse-adjust {
  margin-top: -10px !important;
}

.report-chip {
  background: #FFFFFF;
  padding: 0px 25px;
  border-radius: 36px;
  height: 50px;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  justify-content: space-between;
  color: rgba(25, 32, 36, 0.4);
}

.space-report-chip {
  margin-right: 30px;
  width: 50%;
}

.space-top-report {
  margin-top: 25px;
  margin-bottom: 25px;
}

.report-grid {
  max-width: 1500px;
  width: 100%;}


  .margin-control-date {
    margin-left: -30px;
  }

  .margin-control-time{
    margin-right: 80px;
  }

  .edit-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #1E9294;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: -17px;
  }

  .report-table-content--margin {
    margin-right: 15px;
  }

  .mob-header::after {
    background-image: none;
  }
  .mob-header-bg {
    --background: #f3f4f4;
    background: #f3f4f4;
  }

  .header-title {
    font-size: 26px;
  }

  @media only screen  and (max-width: 600px) {
    .responsive-chip {
      flex-direction: column;
    }
    .space-report-chip {
      width: 100% !important;
    }
    .report-chip {
      height: 40px;
      margin-bottom: 10px;
       font-size: 15px ;

   }
   .report-header {
     height: 45px;
   }

   .report-main-content-table {
     font-size: 14px;
   }
    
  }
  @media only screen and (max-width: 500px) {
    .report-table-label {
      font-size: 11px;
    }

    .report-table-content {
      font-size: 13px;
    }

    .font-fix {
      font-size: 12px;
    }

    .margin-control-time {
      margin-right: 65px;
    }
    .edit-btn {
      width: 20px;
      height: 20px;
    }
    .report-card {
      padding-left: 15px;
      padding-right: 15px;
    }
  }