.icon-rotate {
    transform:translateY(2px)
}

.icon-rotate-active {
    transition: 0.2s;
    transform: rotate(180deg) translateY(-2px);

}

.accordion__content {
    display: none;
}

.accordion__content--active {
    display: block;
}
ion-label {
    cursor: pointer;
}