
.property-image-details {
    width: 75px;
    max-height: 75px;
    border-radius: 6px;
}

.unit-wrap {
    font-size: 14px;
    font-weight: 400;
}

.margin-center {
    margin-left: 50%;
}

.lines {
    border-bottom: 1px solid #DEDEDE;
}