

.text-table-row {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: rgba(25, 32, 36, 0.4);
    margin-bottom: 11px;
    display: inline-block;
    margin-top: 11px;
}

.text-table-row-item {
    color: #192024;
}

.text-table-row:first-child {
margin-left: 14px;
}
.text-table-row:last-child {
    margin-right: 32px;
}
    

.team-pending-table-heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 35px;
    color: #192024;
    display: inline-block;
    margin-top: 30px;
}

.pending-delete-icon {
    font-size: 24px;
    color: rgba(254, 95, 94, 0.7);
    cursor: pointer;
}
.table-border {
    border-bottom: 1px solid rgba(25, 32, 36, 0.2);
    min-height: min-content;

}

.table-row-item:not(:first-child) {
    text-align: center;
}
.table-row-item:last-child {
    text-align: right;
}

@media only screen and (max-width: 550px) {
    .text-table-row:last-child {
        margin-right: 10px;
    }
}