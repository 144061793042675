.member-permissions{
  padding: 15px;
}
.member-permissions .badge {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-primary);
  padding: 5px;
  text-transform: uppercase;
}
.member-permissions .settings-btn {
  text-transform: none;
  padding-bottom: 12px;
}
.success-modal .modal-wrapper {
  height: 40%;
  width: 60%;
}
@media (min-width: 1200px) {
  .success-modal .modal-wrapper {
    height: 40%;
    width: 40%;
  }
}