.animate {
    transition: all .2s;
}

.animate-step {
    transform: translateY(-16px);
    display: none;

}

.animate-step-action {
    transform: translate(0);
    display: block;
    
}