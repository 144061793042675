.rdt_TableRow:nth-child(even) {
    background-color: #f5f5f5;
}

.rdt_TableRow{
    font-size: 15px;
    color: gray;
    line-height: 1.2;
    font-weight: unset;

}

.rdt_TableHead {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;

}