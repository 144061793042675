.team-modal-input{
    border: 1px solid var(--ion-color-primary);
    border-radius: 6px;
    margin: 10px;
}
.select-modal {
    border: 1px solid var(--ion-color-primary);
    border-radius: 7px;

}

.fixed-layout {
    width: 96.5%;
    margin: 10px auto;
}


@media only screen and (max-height: 600px)   {

    .fix-margin {
        margin-left: -10px;
    }
    
}