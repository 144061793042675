.intro-root{
  height: 100%;
}
.intro-root .new-button{
  text-transform: none; 
  margin-left: 20px;
}
.intro-root .modal-bg-img{
  background: url("/assets/images/intro-permission.jpg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  height: 100%;
}
@media (max-width:767px){
  .intro-root .modal-bg-img{
    height: 60%;
    background-position: 30% 48%;
  }
}
@media (max-width:575px){
  .intro-root .modal-bg-img{
    height: 43%;
    background-position: 30% 48%;
  }
}
.intro-root .close-icon{
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 25px;
  cursor: pointer;
}
.intro-root h4{
  font-size: 15px;
  margin-left:5px;
  margin-top: 10px;
}
.intro-root h6{
  font-size: 13px;
  margin-left:20px;
  margin-top: 5px;
}
.intro-root p{
  font-size: 12px;
  margin-left:20px;
  margin-top: 5px;
}