.listing-sec {
  padding: 15px;
}
.listing-sec .heading {
  font-weight: bold;
  font-size: 20px;
}
.listing-sec .rdt_TableHeader {
  display: none;
}
.listing-sec .listing-bg-img {
  width: 90px;
  height: 9vh;
  border-radius: 4px;
  background-position: bottom;
  background-size: cover;
}
.listing-sec .check-circle-icon {
  color: #158a06;
  font-size: 20px;
}
.listing-sec .status-circle {
  height: 8px;
  width: 8px;
  background-color: #158a06;
  border-radius: 10px;
}
.listing-sec .setting-icon {
  font-size: 20px;
}
.confirmation-modal .modal-wrapper {
  height: 40%;
  width: 60%;
}
@media (min-width: 1200px) {
.confirmation-modal .modal-wrapper {
  height: 40%;
  width: 40%;
 }
}
.listing-sec .rdt_TableHeadRow{
    text-transform: uppercase;
}
.listing-sec .listing-searchbar{
    --box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 25px;
    --background: #F7F7F7;
    padding: 0px;
    --border-radius: 25px;
}
.listing-sec .listing-searchbar .searchbar-input{
    padding-top: 2px;
    padding-bottom: 2px;
}
.listing-sec .listing-searchbar .searchbar-search-icon{
    top: 6px;
}
.listing-sec .filter-chip{
    min-height: 34px;
    border-radius: 25px;
}

.postion-set  {
  position: relative;
}

.room-label {
  --placeholder-opacity: 1;
}

