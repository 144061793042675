.view-listing-sec{
  padding: 15px;
}
.view-listing-sec .heading {
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
}
.view-listing-sec .listing-details{
  font-size: 13px;
  text-transform: capitalize;
}

.calendar-item-list {
  --background: #F4F5F9 !important;
  border-radius: 6px;
  margin-top: 10px;
}
.calendar-item-list__cont{
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
  width: 100%;
}


/******* Calendar Brand ******/

.airBnB-active {
  border: 1px solid #F36D71 !important;
}

.booking-active {
  border: 1px solid rgb(7, 63, 218) !important;
}

.vrbo-active {
  border: 1px solid #116DB3  !important;
}

.tripAdvisor-active {
  border: 1px solid #ffb802 !important;
}

.ical-active {
  border: 1px solid black !important;

}


.calendar-cross {
  font-size: 26px;
  padding: 10px;
  border-radius: 33px;
  display: flex;
  cursor: pointer;
  transition: all 0.2s;
}
.calendar-cross:hover {
  background-color: #adadad;
  color: #f4f5f9;
}
.calender-item-list__content {
  display: flex;
  align-items: center ;
}

.c-err {
  color: red;
}