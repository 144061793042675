
.team-root .settings-btn{
    text-transform: capitalize ;
}
.team-root .team-member-heading{
    font-weight: bold;
    font-size: 20px;
}


.dropdown-container {
    position: relative;
}

.team-dropdown {
    background-color: #118B8F;
    padding: 3px;
    border-radius: 5px;
    width: 350px;
    min-height: min-content;
    position: absolute;
    right: 22%;
    top: 100%;
    z-index: 111;
}


.ml-minus{
    margin-left: -19px;
}

.no-team {
    margin-top: 120px;
    font-size: 20px !important;
    color: #6c6c6c;
}


.team-root {
    padding: 15px;
    width: 100%;
    max-width: 1500px;
    margin: auto;
    margin-top: 35px;
}

.team-image {
    width: 110px;
    height: 110px;
    border-radius: 18px;
}

.team-btn-header {
    margin-left: 25px;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 41px;
    color: #1E9294;
}

/* Team Brief */
.team-brief {
    text-align: left;
}

.team-brief__heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #192024;
    margin-top: 22px;
    display: inline-block;
    margin-bottom: 10px;
}
.team-brief__paragraph {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: rgba(25, 32, 36, 0.5);
}


/* Team Button */

.team-btn {
--background: #1E9294;
--border-radius: 24px;

}

.team-member-list {
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(17, 17, 26, 0.1);
    border-radius: 12px;
    padding: 0px ;
}
/* .team-member-item {
    height: 45px;
    padding: 10px;
} */

.team-member-item-padding {
    padding: 10px 15px !important;

}
.team-member-item::part(native) {
    padding: 0px;
}

.team-member-details-text{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #192024;
}

@media screen and (max-width: 500px) {
    .team-image {
        width: 80px;
        height: 80px;
        border-radius: 18px;
    }

    .team-root  {
        margin-top: 0px;
    }
}