.demo {
    font-size: 24px !important;
    margin: 5px 0px;
    display: inline-block;
  }
  
  ion-col {
    font-size: 13px;
  }
  
  .task-details-label {
    display: block !important;
  }
  
  .task-details-fab {
    display: flex;
    align-items: center;
  }
  
  .ion-fab-shadow {
    --box-shadow: 0 3px 5px #ccc !important;
  }
  
  .task-details-label {
    font-size: 16px !important;
    margin-bottom: 10px;
  }
  .task-details-label-e {
    display: block;
    color: #118b8f !important;
    font-weight: bold;
  }
  
  .mf-label {
    font-size: 17px;
    margin-left: 10px;
  }
  