h1 {
    font-size: 28px;
}

h3 {
   font-size:  16px !important;
}

.container-template {
    padding: 1rem;
    margin-left: -15px;
}


.heading-text {
    color: #494949;
}

.label-text {
    font-weight: bold;
    font-size: 20px;
}



.underline {
    height: 5px;
    background-color: aquamarine;
    margin-top: 10px;
    
}

.underline-1 {
    width: 30%;
}

.underline-2 {
    width: 60%;
}
.underline-3 {
    width: 90%;
}

.mr-1 {
    margin: 0 -13px;
}