.team-permissions{
  padding: 15px;
}
.team-permissions .permission-sec {
  padding: 20px 0px;
  color: #484848;
  width: 100%;
}
.team-permissions h6,.team-permissions h3, .team-permissions h1 {
 font-weight: bold;
}
.team-permissions p {
  margin-left: 5px;
 }

.team-permissions .badge {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-primary);
  padding: 5px;
  text-transform: uppercase;
  margin-left: 15px;
  float: right;
}
.team-permissions .chevron-icons {
  font-size: 17px;
  margin-right: 15px;
}
.team-permissions .inner-icons{
  font-size: 17px;
  margin-right: 15px;
  color: var(--ion-color-primary);
}

.team-permissions .list-item{
list-style-type: disc;
padding: 15px 0px 0px 5px;
}
.team-permissions .permission-accordian{
  display: flex;
  flex-direction: row;
}
.team-permissions .list{
  border-bottom: 1px solid #ccc;
  padding: 15px 0px;
  cursor: pointer;
  color: var(--ion-text-color);
}
.team-permissions .text-padding{
  padding-left: 60px;
}
@media (max-width:767px){
  .team-permissions .text-padding{
    padding-left: 0px;
  }
}